import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import React from "react";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import usePlayerSeat from "./usePlayerSeat";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const PlayerSeat = ({seat, player, selectable, onSelect, handleDone, handleEdit, handleMove, handleStart, handlePause, handleUnpause}) => {

    const {anchorEl, open, renderTimer, renderBackgroundColor, handleMenuClose, handleMenuClick} = usePlayerSeat({player, seat, selectable, onSelect});

    return <MDBox onClick={selectable ? onSelect : null} p={1} mb={1} sx={{backgroundColor: renderBackgroundColor(), cursor: selectable ? 'pointer' : 'inherit', ':hover': {borderColor: selectable ? '#ffffff' : 'inherit'}, borderRadius: 2, borderStyle: player ? 'solid' : 'dashed' , display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <MDBox sx={{flex: 1}}>
            <MDTypography variant="button" color={"text"} fontWeight="bold" sx={{display: 'block'}}>
                {seat.name} - {player && renderTimer()}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
                {player ? player.member.name : 'Empty'}
            </MDTypography>
        </MDBox>
        {
            player &&
            <MDButton iconOnly={true} variant="text" size="medium" circular={true} onClick={handleMenuClick}>
                <Icon>more_vert</Icon>
            </MDButton>
        }
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {
                player && player.finished_at === null ?
                    <MenuItem onClick={handleStart}>Start Time</MenuItem> :
                    <>
                    {
                        player && player.paused_at === null &&
                        <MenuItem onClick={handlePause}>Pause Time</MenuItem>
                    }
                        {
                            player && player.paused_at !== null &&
                            <MenuItem onClick={handleUnpause}>Unpause Time</MenuItem>
                        }
                        <MenuItem onClick={handleEdit}>Add Time</MenuItem>
                        <MenuItem onClick={handleMove}>Move Player</MenuItem>
                        <MenuItem onClick={handleDone}>Mark as Done</MenuItem>
                    </>
            }
        </Menu>
    </MDBox>
};

export default PlayerSeat;
