
export const ENVIRONMENT = 'PRODUCTION';

const getAppUrl = () => {
    if(ENVIRONMENT === 'LOCAL')
        return ('http://localhost:8000');

    if(ENVIRONMENT === 'STAGING')
        return 'https://api.rf.astwarehosting.com';

    if(ENVIRONMENT === 'PRODUCTION')
        return 'https://api.theroyalflushsc.com';
};

export const APP_URL = getAppUrl();
export const API_URL = APP_URL + '/api';

export const PUSHER_KEY = '75e86408eb8a86aa13c3';
