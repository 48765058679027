import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";

const useNewPayment = () => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [members, setMembers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'success',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await loadMembers();
        };

        init();

    }, []);

    const loadMembers = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/members`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setMembers([{id: -1, label: "Choose an option"}, ...response.data.map(member => ({id: member.id, label: capitalizeFirstLetter(member.name)}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the members",
                icon: 'cancel',
                title: 'Members',
                color: 'warning'
            });

            setShow(true);

        }

        setIsLoading(false);
    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/payments`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Payment saved successfully',
                icon: 'notifications',
                title: 'Membership',
                color: 'success'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Membership',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        members,
        currentValidation,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewPayment;
