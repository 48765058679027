import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import React from "react";
import avatar from "assets/images/avatar.png";
import usePlayerBox from "./usePlayerBox";
import {APP_URL} from "../../../../../config";

const PlayerBox = ({player}) => {

    const {renderTimer, getTimerColor} = usePlayerBox({player});

    return <MDBox bgColor={getTimerColor()} sx={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderWidth: 0}}>
        <MDBox component="img" src={player ? (player.member.image ? `${APP_URL}/storage/members/${player.member.image}` : avatar) : avatar} alt="Avatar" width="40%" sx={{borderWidth: 2, borderColor: 'red', borderStyle: 'solid', aspectRatio: '1 / 1'}}/>
        <MDTypography variant="subtitle2" style={{textAlign: 'center', marginTop: '8px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%'}}>{player ? player.member.name : 'Empty'}</MDTypography>
        <MDTypography variant="body2" color="white" style={{textAlign: 'center'}}>{player ? renderTimer() : '-'}</MDTypography>
    </MDBox>
};

export default PlayerBox;
