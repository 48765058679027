/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {FieldArray, useFormikContext} from "formik";
import MDBox from "../../../MDBox";
import MDTypography from "../../../MDTypography";
import MDButton from "../../../MDButton";
import colors from "../../../../assets/theme-dark/base/colors";
import FormField from "../../../FormField";

// Material Dashboard 2 PRO React components


function DealerTipsForm({formData}) {

    const formikProps = useFormikContext();

    const {formField, values, errors, touched} = formData;

    const {dealers} = formField;
    const {
        dealers: dealersV
    } = values;

    return (
        <MDBox>
            <FieldArray name={dealers.name}>
                {({insert, remove, push}) => (
                    <>
                        <MDBox sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 3
                        }}>
                            <MDBox>
                                <MDTypography variant="h6">Tips</MDTypography>
                                <MDTypography variant="body2">Add the dealer's tips.</MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox sx={{
                            marginTop: 2,
                            borderWidth: 0.5,
                            borderColor: colors.inputBorderColor,
                            borderRadius: 2,
                            borderStyle: 'solid',
                            padding: 2
                        }}>
                            <Grid container spacing={3} mb={2}>
                                {
                                    values.dealers.map((dealer, index) =>
                                        <Grid key={index} item xs={12} sm={6}>
                                            <FormField
                                                type={dealers.formField.tips_amount.type}
                                                label={dealer.name + ' - Tips'}
                                                name={`${dealers.name}.${index}.${dealers.formField.tips_amount.name}`}
                                                value={dealersV[index].tips_amount}
                                                variant={dealers.formField.tips_amount.variant}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </MDBox>
                    </>
                )}
            </FieldArray>
        </MDBox>
    );
}

export default DealerTipsForm;
