/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-table-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    seat_cost: {
      name: "seat_cost",
      label: "Seat Price",
      type: "number",
      errorMsg: "Seat price is required.",
      invalidMsg: "Seat price must be greater than 0.",
    },
    late_seat_cost: {
      name: "late_seat_cost",
      label: "Late Seat Price",
      type: "number",
      errorMsg: "Late seat price is required.",
      invalidMsg: "Late seat price must be greater than 0.",
    },
    seats: {
      name: "seats",
      type: "array",
      errorMsg: "You must add at least one seat.",
      formField: {
        name: {
          name: "name",
          label: "Seat Name",
          type: "text",
          errorMsg: "Name required.",
          variant: 'standard'
        }
      }
    }
  },
};

export default form;
