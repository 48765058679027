export const formatFilters = (list, columns) => {

    let formattedData = {};

    for(let i = 0; i < columns.length; i++){
        if(list[i].length > 0){
            formattedData[columns[i].name] = list[i];
        }
    }

    return formattedData;
};

export const renderAvatarInitials = (name) => {

    const segments = name.split(' ');

    if(segments.length >= 2)
        return segments[0][0].toUpperCase() + segments[1][0].toUpperCase();
    else
        return segments[0][0].toUpperCase();
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const renderPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
        case '1':
            return 'Cheque Nominativo';
        case '2':
            return 'Efectivo';
        case '3':
            return 'Tarjeta de Crédito';
        case '4':
            return 'Tarjeta de Débito';
        case '5':
            return 'Transferencia Electrónica';
    }
};

export const isEmpty = (array) => {
    return Array.isArray(array) && array.every(isEmpty);
};

export const formatTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    hours = (hours < 10) ? `0${hours}` : hours;
    minutes = (minutes < 10) ? `0${minutes}` : minutes;
    remainingSeconds = (remainingSeconds < 10) ? `0${remainingSeconds}` : remainingSeconds;

    return `${hours}:${minutes}:${remainingSeconds}`;
};
