/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function EditPlayerForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {hours, is_free, is_late_night} = formField;
    const {
        hours: hoursV,
        is_free: isFreeV,
        is_late_night: isLateNightV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={hours.type}
                        label={hours.label}
                        name={hours.name}
                        value={hoursV}
                        error={errors.hours && touched.hours}
                        success={hoursV && hoursV.length > 0 && !errors.hours}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={is_free.type}
                        label={is_free.label}
                        name={is_free.name}
                        value={isFreeV}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={is_late_night.type}
                        label={is_late_night.label}
                        name={is_late_night.name}
                        value={isLateNightV}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditPlayerForm;
