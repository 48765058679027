/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
// Material Dashboard 2 PRO React components


function AssignDealerForm({formData, dealers}) {

    const {formField, values, errors, touched} = formData;

    const {dealer_id} = formField;
    const {
        dealer_id: dealerIdV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={dealer_id.type}
                        label={dealer_id.label}
                        name={dealer_id.name}
                        value={dealerIdV}
                        options={dealers}
                        error={errors.dealer_id && touched.dealer_id}
                        success={dealerIdV && dealerIdV.length > 0 && !errors.dealer_id}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default AssignDealerForm;
