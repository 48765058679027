/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    name,
      phone,
      birth_at,
      official_id,
      image,
      referred_by
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [phone.name]: "",
  [birth_at.name]: "",
  [official_id.name]: "",
  [image.name]: "",
  [referred_by.name]: "",
};

export default initialValues;
