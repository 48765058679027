/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import colors from "../../../../../assets/theme-dark/base/colors";
import {FieldArray} from "formik";
// Material Dashboard 2 PRO React components


function EditMemberForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, phone, birth_at, official_id, image, referred_by, end_at} = formField;
    const {
        name: nameV,
        phone: phoneV,
        birth_at: birthAtV,
        official_id: officialIdV,
        image: imageV,
        referred_by: referredByV,
        end_at: endAtV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={phone.type}
                        label={phone.label}
                        name={phone.name}
                        value={phoneV}
                        error={errors.phone && touched.phone}
                        success={phoneV && phoneV.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={birth_at.type}
                        label={birth_at.label}
                        name={birth_at.name}
                        value={birthAtV}
                        options={{enableTime: false, disableMobile: true, dateFormat: 'Y-m-d', minDate: new Date()}}
                        placeholder={birth_at.placeholder}
                        error={errors.birth_at && touched.birth_at}
                        success={birthAtV && birthAtV.length > 0 && !errors.birth_at}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={referred_by.type}
                        label={referred_by.label}
                        name={referred_by.name}
                        value={referredByV}
                        error={errors.referred_by && touched.referred_by}
                        success={referredByV && referredByV.length > 0 && !errors.referred_by}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={image.type}
                        label={image.label}
                        name={image.name}
                        value={imageV}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="image"
                        error={errors.image && touched.image}
                        success={imageV && imageV.length > 0 && !errors.image}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={official_id.type}
                        label={official_id.label}
                        name={official_id.name}
                        value={officialIdV}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="image"
                        error={errors.official_id && touched.official_id}
                        success={officialIdV && officialIdV.length > 0 && !errors.official_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={end_at.type}
                        label={end_at.label}
                        name={end_at.name}
                        options={{enableTime: false, disableMobile: true, dateFormat: 'Y-m-d', minDate: new Date()}}
                        value={endAtV}
                        error={errors.end_at && touched.end_at}
                        success={endAtV && endAtV.length > 0 && !errors.end_at}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditMemberForm;
