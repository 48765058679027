/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "assign-player-form",
  formField: {
    hours: {
      name: "hours",
      label: "Playing Half Hours",
      type: "number",
      errorMsg: "Playing hours is required.",
      invalidMsg: 'Must be greater than 0'
    },
    is_free: {
      name: "is_free",
      label: "Gift Free Seating?",
      type: "checkbox",
    },
    is_late_night: {
      name: "is_late_night",
      label: "Is Late Night Pricing?",
      type: "checkbox",
    }
  },
};

export default form;
