/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, phone, official_id, image, referred_by, end_at },
} = checkout;

const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [phone.name]: Yup.string().required(phone.errorMsg),
    [referred_by.name]: Yup.string().required(referred_by.errorMsg),
    [end_at.name]: Yup.date().required(end_at.errorMsg),

});

export default validations;
