import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL, PUSHER_KEY} from "../../../config";
import {useSnackbar} from "../../../context/SnackbarContext/SnackbarContext";
import {useSelector} from "react-redux";
import Pusher from "pusher-js";
import {useParams} from 'react-router-dom';
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import {Card} from "@mui/material";
import PlayerBox from "./components/PlayerBox/PlayerBox";

const useTablePlayerView = () => {

    const { id } = useParams();

    const sessionState = useSelector((state) => state.sessionReducer);

    const {showSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);

    const [tableSeats, setTableSeats] = useState([]);
    const [tableMembers, setTableMembers] = useState([]);


    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadTableSeats();
            await loadTableMembers();
            setIsLoading(false);
        };

        init();

        const interval = setInterval(() => {
            // Your function to be executed every minute
            init();
        }, 30000);

        init();

    }, []);

    const updateMemberSession = (memberSession) => {

        setTableMembers(prevSessionMembers => {
            let currentIndex = prevSessionMembers.findIndex(sessionMember => sessionMember.id === memberSession.id);

            let newSessionMembers = [...prevSessionMembers];

            if (currentIndex === -1) {

                newSessionMembers.push(memberSession);

                // If the memberSession is not found, you may choose to return prevSessionMembers unmodified, or add the memberSession to the array.
                return newSessionMembers;
            }

            newSessionMembers[currentIndex] = memberSession;

            return newSessionMembers;
        });

    };

    const loadTableSeats = async () => {

        try {

            const response = await axios.get(`${API_URL}/tables/${id}`);

            setTableSeats(response.data.seats);

        } catch (error) {

            showSnackbar('Tables', 'Something went wrong loading the table', 'cancel', 'warning');

        }

    };

    const loadTableMembers = async () => {

        try {

            const response = await axios.get(`${API_URL}/session/members`, {
                params: {
                    sort: 'seat_id',
                    direction: 'asc',
                    status: 'playing'
                }
            });

            setTableMembers(response.data);

        } catch (error) {

            showSnackbar('Session Members', 'Something went wrong loading the table members', 'cancel', 'warning');

        }

    };

    const renderPlayers = () => {

        return tableSeats.map((seat, index) => {
            switch(index){
                case 5:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(310deg) translate(300px) rotate(-310deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 6:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(340deg) translate(360px) rotate(-340deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 7:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(15deg) translate(360px) rotate(-15deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 8:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(45deg) translate(290px) rotate(-45deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 0:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(140deg) translate(320px) rotate(-140deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 1:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(165deg) translate(430px) rotate(-165deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 2:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(195deg) translate(420px) rotate(-195deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 3:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(225deg) translate(330px) rotate(-225deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
                case 4:
                    return <Card sx={{padding: '8px', position: 'absolute', width: 120, height: 120, top: '51%', left: '51%', margin: '-51px', transform:'rotate(270deg) translate(240px) rotate(-270deg)'}}>
                        {renderPlayer(seat.id)}
                    </Card>;
            }
        })
    };

    const renderPlayer = (seatId) => {

        let seatMember = tableMembers.find(member => member.seat_id === seatId);

        return <PlayerBox player={seatMember} />
    };

    return {
        isLoading,
        tableSeats,
        tableMembers,
        sessionState,
        renderPlayers
    };
};

export default useTablePlayerView;
