import {END_SESSION, START_SESSION} from "../actionTypes";


export const startSession = (startAt) => async dispatch => {
    dispatch({
        type: START_SESSION,
        payload: { startAt }
    });
};

// Acción para cerrar sesión
export const endSession = () => async dispatch => {
    dispatch({ type: END_SESSION });
};

