import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import form from "../members/checkin/schemas/form";
import validations from "../members/checkin/schemas/validations";
import {capitalizeFirstLetter} from "../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useSnackbar} from "../../context/SnackbarContext/SnackbarContext";
import {API_URL} from "../../config";
import TableCell from "@mui/material/TableCell/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useWaitingList = () => {

    const sessionState = useSelector((state) => state.sessionReducer);

    const { showSnackbar } = useSnackbar();

    const [members, setMembers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const interval = setInterval(() => {
            // Your function to be executed every minute
            init();
        }, 15000);

        init();

    }, []);

    const init = async () => {
        setIsLoading(true);
        await loadMembers();
        setIsLoading(false);
    };

    const renderMembers = () => {
        return members.map(member => (
            <TableRow
                key={member.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row" style={{fontSize: 24}}>
                    {member.member.name}
                </TableCell>
                <TableCell align="right" style={{fontSize: 24}}>{member.arrived_at}</TableCell>
                <TableCell align="right" style={{fontSize: 24}}>
                    <IconButton color="white" onClick={() => handlePlayerRemove(member.id)}>
                        <CloseIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ))
    };

    const loadMembers = async () => {

        try {

            const response = await axios.get(`${API_URL}/session/members`, {
                params: {
                    sort: 'arrived_at',
                    direction: 'asc',
                    active: true,
                    filters: '{"status": ["waiting"]}',
                }
            });

            setMembers(response.data);

        } catch (error) {

            showSnackbar('Waiting List', 'Something went wrong loading the members', 'cancel', 'warning');

        }

    };

    const handlePlayerRemove = async (id) => {

        try {

            const response = await axios.put(`${API_URL}/session/members/${id}/remove`);

            await init();

        } catch (error) {

            showSnackbar('Waiting List', 'Something went wrong removing the member', 'cancel', 'warning');

        }

    };


    return {
        isLoading,
        members,
        sessionState,
        renderMembers
    };
};

export default useWaitingList;
