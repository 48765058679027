import {useSelector} from "react-redux";
import {useSnackbar} from "../../../context/SnackbarContext/SnackbarContext";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import form from "./schemas/form";
import validations from "./schemas/validations";
import {capitalizeFirstLetter} from "../../../helpers/helpers";

const DealerTable = ({id, name, dealer, onButtonClick}) => {
    return <MDBox p={2} lineHeight={1}
                  sx={{borderWidth: 1, borderStyle: 'solid', borderRadius: 4}}>
        <MDBox>
            <MDBox mb={1}
                   sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <MDTypography mb={1} variant="h6" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDButton onClick={onButtonClick} iconOnly={true} circular={true}>
                    <Icon>{dealer ? 'cancel' : 'add'}</Icon>
                </MDButton>
            </MDBox>
            <MDBox p={1} sx={{borderStyle: 'dashed', borderWidth: 1, borderRadius: 2}}>
                <MDTypography variant="button">
                    {dealer ? dealer.dealer?.name : 'Unassigned'}
                </MDTypography>
            </MDBox>
        </MDBox>
    </MDBox>
};

const useDealersDashboard = () => {

    const sessionState = useSelector((state) => state.sessionReducer);

    const {formId, formField} = form;
    const currentValidation = validations;

    const [modalOpen, setModalOpen] = useState(false);
    const handleClose = () => setModalOpen(false);

    const {showSnackbar} = useSnackbar();

    const [tables, setTables] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [dealerSessions, setDealerSessions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [selectedTable, setSelectedTable] = useState(null);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadDealerSessions();
            await loadTables();
            await loadDealers();
            setIsLoading(false);
        };

        init();

    }, []);

    const reloadSessions = async () => {
        setIsLoading(true);
        await loadDealerSessions();
        setIsLoading(false);
    };

    const loadDealerSessions = async () => {

        try {

            const response = await axios.get(`${API_URL}/session/dealers`, {
                params: {
                    sort: 'table_id',
                    direction: 'asc'
                }
            });

            if (response.data.length > 0)
                setDealerSessions(response.data);

        } catch (error) {

            showSnackbar('Tables', 'Something went wrong loading the tables', 'cancel', 'warning');

        }

    };

    const loadTables = async () => {

        try {

            const response = await axios.get(`${API_URL}/tables`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if (response.data.length > 0)
                setTables(response.data);

        } catch (error) {

            showSnackbar('Tables', 'Something went wrong loading the tables', 'cancel', 'warning');

        }

    };

    const loadDealers = async () => {

        try {

            const response = await axios.get(`${API_URL}/dealers`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if (response.data.length > 0)
                setDealers([{id: -1, label: "Choose an option"}, ...response.data.map(dealer => ({id: dealer.id, label: dealer.name}))]);

        } catch (error) {

            showSnackbar('Dealers', 'Something went wrong loading the dealers', 'cancel', 'warning');

        }

    };

    const handleButtonClick = (table, dealerSession) => {

        if(dealerSession)
            handleRemove(dealerSession);
        else
            handleAdd(table);
    };

    const handleRemove = async (session) => {

        try {

            // Enviar datos a API
            await axios.delete(
                `${API_URL}/session/dealers/${session.id}`
            );

            showSnackbar('Dealers', 'Dealer successfully removed', 'notifications', 'success');

            await reloadSessions();
        } catch (error) {

            showSnackbar('Dealers', error.response.data.message, 'cancel', 'warning');

        }

    };

    const handleAdd = async (table) => {
        setSelectedTable(table.id);
        setModalOpen(true);
    };

    const handleAssign = async (values, actions) => {

        try {

            let data = {...values};

            data.table_id = selectedTable;

            // Enviar datos a API
            await axios.post(
                `${API_URL}/session/dealers`,
                {...data}
            );

            actions.resetForm();
            setSelectedTable(null);
            setModalOpen(false);

            showSnackbar('Dealers', 'Dealer successfully assigned', 'notifications', 'success');

            await reloadSessions();

        } catch (error) {

            showSnackbar('Dealers', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);

    };

    const renderTables = () => {
        return tables.map(table => {

            const dealerSession = dealerSessions.find(session => (session.table_id === table.id && session.finished_at === null))

            return <Grid item sx={{minWidth: '25%'}}>
                <DealerTable onButtonClick={() => handleButtonClick(table, dealerSession)} id={table.id} name={table.name}
                             dealer={dealerSession}/>
            </Grid>
        })
    };

    return {
        formId,
        formField,
        currentValidation,
        tables,
        dealers,
        sessionState,
        showSnackbar,
        isLoading,
        modalOpen,
        handleClose,
        handleAssign,
        renderTables
    }
};

export default useDealersDashboard;
