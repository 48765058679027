/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import colors from "../../../../../assets/theme-dark/base/colors";
import {FieldArray} from "formik";
// Material Dashboard 2 PRO React components


function EditTableForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, seat_cost, late_seat_cost, seats} = formField;
    const {
        name: nameV,
        seat_cost: seatCostV,
        late_seat_cost: lateSeatCostV,
        seats: seatsV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={seat_cost.type}
                        label={seat_cost.label}
                        name={seat_cost.name}
                        value={seatCostV}
                        error={errors.seat_cost && touched.seat_cost}
                        success={seatCostV && seatCostV.length > 0 && !errors.seat_cost}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={late_seat_cost.type}
                        label={late_seat_cost.label}
                        name={late_seat_cost.name}
                        value={lateSeatCostV}
                        error={errors.late_seat_cost && touched.late_seat_cost}
                        success={lateSeatCostV && lateSeatCostV.length > 0 && !errors.late_seat_cost}
                    />
                </Grid>
            </Grid>
            <FieldArray name={seats.name}>
                {({insert, remove, push}) => (
                    <>
                        <MDBox sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 3
                        }}>
                            <MDBox>
                                <MDTypography variant="h6">Seats</MDTypography>
                                <MDTypography variant="body2">Add the table's seats.</MDTypography>
                            </MDBox>
                            <MDButton color="secondary" variant="outlined"
                                      onClick={() => push({name: 'Seat ' + (seatsV.length + 1)})}>
                                <Icon>add</Icon>
                                Add Seat
                            </MDButton>
                        </MDBox>
                        <MDBox sx={{ marginTop: 2, borderWidth: 0.5, borderColor: colors.inputBorderColor, borderRadius: 2, borderStyle: 'solid', padding: 2}}>
                            {
                                values.seats.length === 0 &&
                                <MDTypography variant="body2" textAlign="center">Add at least two seats</MDTypography>
                            }
                            {values.seats.length > 0 &&
                            values.seats.map((seat, index) => (
                                <Grid container spacing={3} alignItems="center" justifyContent="center" key={index}
                                      mb={2}>
                                    <Grid item xs={12} sm={11}>
                                        <FormField
                                            type={seats.formField.name.type}
                                            label={seats.formField.name.label + ' #' + (index + 1)}
                                            name={`${seats.name}.${index}.${seats.formField.name.name}`}
                                            value={seatsV[index].name}
                                            variant={seats.formField.name.variant}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <MDButton
                                            type="button"
                                            color="error"
                                            variant="outlined"
                                            size="small"
                                            iconOnly={true}
                                            onClick={() => remove(index)}
                                        >
                                            <Icon>close</Icon>
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </MDBox>
                    </>
                )}
            </FieldArray>
        </MDBox>
    );
}

export default EditTableForm;
