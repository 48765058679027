/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import Settings from "layouts/pages/account/settings";
// Material Dashboard 2 PRO React components
// @mui icons
import Icon from "@mui/material/Icon";
// Images
import Users from "./layouts/users/view/Users";
import Dealers from "./layouts/dealers/view/Dealers";
import Tables from "./layouts/tables/view/Tables";
import Members from "./layouts/members/view/Members";
import CheckIn from "./layouts/members/checkin/CheckIn";
import Lobby from "./layouts/dashboards/lobby/Lobby";
import Transactions from "./layouts/transactions/view/Transactions";
import EditSettings from "./layouts/settings/edit-settings/EditSettings";
import Sessions from "./layouts/sessions/view/Sessions";
import Session from "./layouts/dashboards/session/Session";
import DealersDashboard from "./layouts/dashboards/dealers/DealersDashboard";
import WaitingList from "./layouts/waiting_list/WaitingList";
import Payments from "./layouts/payments/view/Payments";

export const adminRoutes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/dashboards/overview",
        component: <Session />,
      },
      {
        name: "Lobby",
        key: "lobby",
        route: "/dashboards/lobby",
        component: <Lobby />,
      },
      {
        name: "Dealers",
        key: "dealers",
        route: "/dashboards/dealers",
        component: <DealersDashboard />,
      },
      {
        name: "Check In",
        key: "check-in",
        route: "/dashboards/check-in",
        component: <CheckIn />,
      },
      {
        name: "Waiting List",
        key: "waiting-list",
        route: "/dashboards/waiting-list",
        component: <WaitingList />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="medium">point_of_sale</Icon>,
    route: "/transactions",
    component: <Transactions/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Members",
    key: "members",
    icon: <Icon fontSize="medium">assignment_ind</Icon>,
    route: "/members",
    component: <Members/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="medium">payment</Icon>,
    route: "/payments",
    component: <Payments/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sessions",
    key: "sessions",
    icon: <Icon fontSize="medium">access_time</Icon>,
    route: "/sessions",
    component: <Sessions/>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Data", key: "title-docs" },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">person</Icon>,
    route: "/users",
    component: <Users/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dealers",
    key: "dealers",
    icon: <Icon fontSize="medium">face</Icon>,
    route: "/dealers",
    component: <Dealers/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="medium">table_restaurant</Icon>,
    route: "/tables",
    component: <Tables/>,
    noCollapse: true,
  }
];

export const staffRoutes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/dashboards/overview",
        component: <Session />,
      },
      {
        name: "Lobby",
        key: "lobby",
        route: "/dashboards/lobby",
        component: <Lobby />,
      },
      {
        name: "Dealers",
        key: "dealers",
        route: "/dashboards/dealers",
        component: <DealersDashboard />,
      },
      {
        name: "Check In",
        key: "check-in",
        route: "/dashboards/check-in",
        component: <CheckIn />,
      },
      {
        name: "Waiting List",
        key: "waiting-list",
        route: "/dashboards/waiting-list",
        component: <WaitingList />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="medium">point_of_sale</Icon>,
    route: "/transactions",
    component: <Transactions/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Members",
    key: "members",
    icon: <Icon fontSize="medium">assignment_ind</Icon>,
    route: "/members",
    component: <Members/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="medium">payment</Icon>,
    route: "/payments",
    component: <Payments/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sessions",
    key: "sessions",
    icon: <Icon fontSize="medium">access_time</Icon>,
    route: "/sessions",
    component: <Sessions/>,
    noCollapse: true,
  },
];

export const hostRoutes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Lobby",
        key: "lobby",
        route: "/dashboards/lobby",
        component: <Lobby />,
      },
      {
        name: "Dealers",
        key: "dealers",
        route: "/dashboards/dealers",
        component: <DealersDashboard />,
      },
      {
        name: "Check In",
        key: "check-in",
        route: "/dashboards/check-in",
        component: <CheckIn />,
      },
      {
        name: "Waiting List",
        key: "waiting-list",
        route: "/dashboards/waiting-list",
        component: <WaitingList />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="medium">point_of_sale</Icon>,
    route: "/transactions",
    component: <Transactions/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Members",
    key: "members",
    icon: <Icon fontSize="medium">assignment_ind</Icon>,
    route: "/members",
    component: <Members/>,
    noCollapse: true,
  }
];
