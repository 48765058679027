/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "dealer-form",
  formField: {
    dealer_id: {
      name: "dealer_id",
      label: "Dealer",
      type: "select",
      errorMsg: "Dealer is required.",
      invalidMsg: 'Must choose a dealer'
    }
  },
};

export default form;
