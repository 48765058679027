import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import {useParams} from 'react-router-dom';
import jsonToFormData from "json-form-data";

const useEditMember = () => {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'success',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {

            setIsLoading(true);

            await loadRecord();

            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/members/${id}`);

            setValues(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the member's details",
                icon: 'cancel',
                title: 'Members',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        try {

            let options = {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: true
            };

            let data = {...values};

            data._method = 'PUT';

            if(typeof data.image === 'string')
                delete data.image;

            if(typeof data.official_id === 'string')
                delete data.official_id;

            const formData = jsonToFormData(data, options);

            // Enviar datos a API
            await axios.post(
                `${API_URL}/members/${id}`,
                formData
            );

            setSnackbarConfig({
                message: 'Member updated successfully',
                icon: 'notifications',
                title: 'Members',
                color: 'success'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Members',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        formId,
        formField,
        currentValidation,
        values,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        handleSubmit
    }
};

export default useEditMember;
