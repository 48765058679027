import React, {useEffect, useRef, useState} from "react";
import moment from "moment";

const usePlayerBox = ({player}) => {

    const [timer, setTimer] = useState(null);

    const timerInterval = useRef();
    const timerValue = useRef();

    useEffect(() => {

        if(!player){
            setTimer(null);
            return;
        }

        let secondsLeft = 0;

        if(moment(player.finished_at) > moment()){
            secondsLeft = moment(player.finished_at).diff(moment(), 'seconds');
        }

        timerValue.current = secondsLeft;
        setTimer(secondsLeft);
        startTimer();

    }, [player, player?.finished_at]);

    const startTimer = () => {

        clearInterval(timerInterval.current);

        timerInterval.current = setInterval(() => {

            if (timerValue.current > 0) {
                timerValue.current -= 1; // Update the timer value in the ref
                setTimer(timerValue.current);
            } else {
                clearInterval(timerInterval.current);
            }
        }, 1000);
    };

    const renderTimer = () => {

        if(player.paused_at !== null){
            return 'PAUSED';
        }

        const minutes = Math.floor(timer / 60);
        const remainingSeconds = timer % 60;

        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${paddedMinutes}:${paddedSeconds}`;
    };

    const getTimerColor = () => {

        if(timer) {
            if (timer < 360 && timer > 120) {
                return 'warning';
            } else if (timer <= 120) {
                return 'secondary';
            }
        }
    };

    return {
        renderTimer,
        getTimerColor
    };
};

export default usePlayerBox;
