/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, seats, seat_cost, late_seat_cost },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [seat_cost.name]: Yup.number().required(seat_cost.errorMsg).positive(seat_cost.invalidMsg),
    [late_seat_cost.name]: Yup.number().required(late_seat_cost.errorMsg).positive(late_seat_cost.invalidMsg),
  [seats.name]: Yup.array().of(
      Yup.object()
          .shape({
            name: Yup.string().required(seats.formField.name.errorMsg)
          })
          .required(seats.errorMsg)
  )
});

export default validations;
