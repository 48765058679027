/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-member-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      errorMsg: "Phone is required.",
    },
    birth_at: {
      name: "birth_at",
      label: "Birthday",
      type: "date"
    },
    official_id: {
      name: "official_id",
      label: "ID File",
      type: "file",
      errorMsg: "ID is required.",
    },
    image: {
      name: "image",
      label: "Photo",
      type: "file",
      errorMsg: "Photo is required.",
    },
    referred_by: {
      name: "referred_by",
      label: "Referred By",
      type: "text",
      errorMsg: "Referred by is required.",
    }
  },
};

export default form;
