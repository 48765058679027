import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL, PUSHER_KEY} from "../../../config";
import {useSnackbar} from "../../../context/SnackbarContext/SnackbarContext";
import {useSelector} from "react-redux";
import MDBox from "../../../components/MDBox";
import DefaultItem from "../../../components/Items/DefaultItem";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PlayerSeat from "./components/PlayerSeat/PlayerSeat";
import assignForm from "./schemas/assign/form";
import assignValidations from "./schemas/assign/validations";
import doneForm from "./schemas/done/form";
import doneValidations from "./schemas/done/validations";
import editForm from "./schemas/edit/form";
import editValidations from "./schemas/edit/validations";
import Pusher from "pusher-js";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PauseIcon from '@mui/icons-material/Pause';
import {useNavigate} from "react-router-dom";

const useLobby = () => {

    let navigate = useNavigate();

    const {formId: assignFormId, formField: assignFormField} = assignForm;
    const assignCurrentValidation = assignValidations;

    const [assignModalOpen, setAssignModalOpen] = React.useState(false);
    const handleAssignClose = () => setAssignModalOpen(false);

    const {formId: doneFormId, formField: doneFormField} = doneForm;
    const doneCurrentValidation = doneValidations;

    const [doneModalOpen, setDoneModalOpen] = React.useState(false);
    const handleDoneClose = () => setDoneModalOpen(false);

    const {formId: editFormId, formField: editFormField} = editForm;
    const editCurrentValidation = editValidations;

    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const handleEditClose = () => setEditModalOpen(false);

    const sessionState = useSelector((state) => state.sessionReducer);

    const {showSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);

    // const [seatCost, setSeatCost] = useState(0);

    const [sessionMembers, setSessionMembers] = useState([]);
    const [tables, setTables] = useState([]);

    const [selectedMemberSession, setSelectedMemberSession] = useState(null);
    const [selectedSeat, setSelectedSeat] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const [isMoving, setIsMoving] = useState(false);


    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            // await loadSettings();
            await loadSessionMembers();
            await loadTables();
            setIsLoading(false);
        };

        init();

        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = true;

        const pusher = new Pusher(PUSHER_KEY, {
            cluster: 'us2'
        });

        const channel = pusher.subscribe('member_sessions');
        channel.bind('member_session_updated', function (data) {
            updateMemberSession(data.memberSession);
        });

    }, []);

    const updateMemberSession = (memberSession) => {

        setSessionMembers(prevSessionMembers => {
            let currentIndex = prevSessionMembers.findIndex(sessionMember => sessionMember.id === memberSession.id);

            let newSessionMembers = [...prevSessionMembers];

            if (currentIndex === -1) {

                newSessionMembers.push(memberSession);

                // If the memberSession is not found, you may choose to return prevSessionMembers unmodified, or add the memberSession to the array.
                return newSessionMembers;
            }

            newSessionMembers[currentIndex] = memberSession;

            return newSessionMembers;
        });

    };

    // const loadSettings = async () => {
    //     try {
    //
    //         const response = await axios.get(`${API_URL}/settings/1`);
    //
    //         setSeatCost(response.data.seat_unit_cost);
    //
    //     } catch (error) {
    //
    //         showSnackbar('Settings', 'Something went wrong loading the app settings', 'cancel', 'warning');
    //
    //     }
    // };

    const loadSessionMembers = async () => {

        try {

            const response = await axios.get(`${API_URL}/session/members`, {
                params: {
                    sort: 'arrived_at',
                    direction: 'asc'
                }
            });

            if (response.data.length > 0)
                setSessionMembers(response.data);

        } catch (error) {

            showSnackbar('Session Members', 'Something went wrong loading the session members', 'cancel', 'warning');

        }

    };

    const loadTables = async () => {

        try {

            const response = await axios.get(`${API_URL}/tables`, {
                params: {
                    sort: 'id',
                    direction: 'asc',
                    active: true
                }
            });

            if (response.data.length > 0)
                setTables(response.data);

        } catch (error) {

            showSnackbar('Tables', 'Something went wrong loading the tables', 'cancel', 'warning');

        }

    };

    const handleMove = async (sessionMemberId, seatId) => {

        try {

            let data = {};

            data.seat_id = seatId;
            data.is_moving = true;

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${sessionMemberId}`,
                {...data}
            );

            actions.resetForm();
            setSelectedMemberSession(null);
            setSelectedSeat(null);
            setIsMoving(false);

            showSnackbar('Lobby', 'Member successfully seated', 'notifications', 'success');

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);
    };

    const handleAssign = async (values, actions) => {

        try {

            let data = {...values};

            data.seat_id = selectedSeat;

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${selectedMemberSession}`,
                {...data}
            );

            actions.resetForm();
            setSelectedMemberSession(null);
            setSelectedSeat(null);
            setAssignModalOpen(false);

            showSnackbar('Lobby', 'Member successfully seated', 'notifications', 'success');

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);
    };

    const handleDone = async (player) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${player}/done`
            );

            showSnackbar('Lobby', 'Member successfully updated', 'notifications', 'success');

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }
    };

    const handleStart = async (id) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${id}/start`
            );

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }
    };

    const handlePauseAll = async (table) => {

        table.seats.map(async (seat, index) => {

            let seatPlayer = sessionMembers.filter(sessionMember => sessionMember.status === 'playing').find(sessionMember => sessionMember.seat_id === seat.id);

            if(seatPlayer){
                try {

                    // Enviar datos a API
                    await axios.put(
                        `${API_URL}/session/members/${seatPlayer.id}/pause`
                    );

                } catch (error) {

                    showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

                }
            }

        });
    };
    const handlePause = async (id) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${id}/pause`
            );

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }
    };

    const handleUnpause = async (id) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${id}/unpause`
            );

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }
    };

    const handleEdit = async (values, actions) => {

        try {

            let data = {...values};

            // Enviar datos a API
            await axios.put(
                `${API_URL}/session/members/${selectedPlayer}/add`,
                {...data}
            );

            actions.resetForm();
            setSelectedPlayer(null);
            setEditModalOpen(false);

            showSnackbar('Lobby', 'Member successfully updated', 'notifications', 'success');

        } catch (error) {

            showSnackbar('Lobby', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);
    };

    const handleSeatSelect = (seatId, table) => {
        setSelectedSeat(seatId);
        setSelectedTable(table);

        if(isMoving){
            handleMove(selectedMemberSession, seatId);

        } else {
            setAssignModalOpen(true);
        }
    };

    const handleDoneSelect = async (sessionMemberId, table) => {
        setSelectedTable(table);
        await handleDone(sessionMemberId)
    };

    const handleEditSelect = (sessionMemberId, table) => {
        setSelectedPlayer(sessionMemberId);
        setSelectedTable(table);
        setEditModalOpen(true);
    };

    const handleMoveSelect = (sessionMemberId) => {
        setSelectedMemberSession(sessionMemberId);
        setIsMoving(true);
    };

    const getSeatCost = () => {
        const now = new Date();
        const hour = now.getHours();

        let isBetween1AMAnd6AM = hour >= 1 && hour <= 6;

        if(isBetween1AMAnd6AM)
            return selectedTable.late_seat_cost;
        else
            return selectedTable.seat_cost;
    }

    const renderMembersList = () => {
        return sessionMembers.filter(memberSession => memberSession.status === 'waiting').map(memberSession => (
            <MDBox px={1} mb={1}>
                <MDBox>
                    <DefaultItem
                        handleItemClick={() => setSelectedMemberSession(memberSession.id)}
                        selected={selectedMemberSession === memberSession.id}
                        color="dark"
                        icon="savings"
                        title={memberSession.member.name}
                        description={memberSession.arrived_at}
                    />
                </MDBox>
            </MDBox>
        ))
    };

    const renderTableSeats = (table) => {
        return table.seats.map((seat, index) => {

            let seatPlayer = sessionMembers.filter(sessionMember => sessionMember.status === 'playing').find(sessionMember => sessionMember.seat_id === seat.id);

            return <PlayerSeat
                onSelect={() => handleSeatSelect(seat.id, table)}
                selectable={selectedMemberSession !== null && !seatPlayer}
                seat={seat}
                player={seatPlayer}
                handleDone={() => handleDoneSelect(seatPlayer.id, table)}
                handleEdit={() => handleEditSelect(seatPlayer.id, table)}
                handleMove={() => handleMoveSelect(seatPlayer.id)}
                handleStart={() => handleStart(seatPlayer.id)}
                handlePause={() => handlePause(seatPlayer.id)}
                handleUnpause={() => handleUnpause(seatPlayer.id)}
            />
        })
    };

    const renderTables = () => {
        return tables.map(table => <Grid item sx={{minWidth: '25%'}}>
            <MDBox p={2} lineHeight={1}
                   sx={{borderWidth: 1, borderStyle: 'solid', borderRadius: 4, maxHeight: '85vh', overflowY: 'scroll'}}>
                <MDBox
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <MDBox sx={{flexGrow: 1}}>
                        <MDTypography variant="h6" fontWeight="medium">
                            {table.name}
                        </MDTypography>
                        {/*<MDTypography variant="button" color="text" fontWeight="regular">*/}
                        {/*    Dealer #1*/}
                        {/*</MDTypography>*/}
                    </MDBox>
                    <IconButton color="white" onClick={() => handlePauseAll(table)}>
                        <PauseIcon />
                    </IconButton>
                    <IconButton color="white" onClick={() => navigate(`/tables/${table.id}/players`)}>
                        <VisibilityIcon />
                    </IconButton>
                </MDBox>
                <Divider/>
                {
                    renderTableSeats(table)
                }
            </MDBox>
        </Grid>)
    };

    return {
        assignCurrentValidation,
        assignFormId,
        assignFormField,
        doneCurrentValidation,
        doneFormId,
        doneFormField,
        editCurrentValidation,
        editFormId,
        editFormField,
        isLoading,
        sessionMembers,
        sessionState,
        tables,
        assignModalOpen,
        doneModalOpen,
        editModalOpen,
        selectedTable,
        handleAssignClose,
        handleDoneClose,
        handleEditClose,
        renderMembersList,
        getSeatCost,
        renderTables,
        handleAssign,
        handleDone,
        handleEdit
    };
};

export default useLobby;
