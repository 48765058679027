import colors from "../../assets/theme-dark/base/colors";
import MDBox from "../MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "../MDTypography";
import Card from "@mui/material/Card";
import React from "react";

const Message = ({title, message, icon}) => {

    return <Card sx={{backgroundColor: colors.secondary.main}}>
        <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" py={1} px={3}>
            <Icon sx={{color: colors.white.main}}>{icon}</Icon>
            <MDBox ml={2}>
                <MDTypography variant="h6">{title}</MDTypography>
                <MDTypography variant="body2">{message}</MDTypography>
            </MDBox>
        </MDBox>
    </Card>
};

export default Message;
