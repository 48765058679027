/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewMemberForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, phone, birth_at, official_id, image, referred_by, amount} = formField;
    const {
        name: nameV,
        phone: phoneV,
        birth_at: birthAtV,
        official_id: officialIdV,
        image: imageV,
        referred_by: referredByV,
        amount: amountV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={phone.type}
                        label={phone.label}
                        name={phone.name}
                        value={phoneV}
                        error={errors.phone && touched.phone}
                        success={phoneV.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={birth_at.type}
                        label={birth_at.label}
                        name={birth_at.name}
                        value={birthAtV}
                        options={{enableTime: false, disableMobile: true, dateFormat: 'Y-m-d', maxDate: new Date()}}
                        placeholder={birth_at.placeholder}
                        error={errors.birth_at && touched.birth_at}
                        success={birthAtV.length > 0 && !errors.birth_at}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={referred_by.type}
                        label={referred_by.label}
                        name={referred_by.name}
                        value={referredByV}
                        error={errors.referred_by && touched.referred_by}
                        success={referredByV.length > 0 && !errors.referred_by}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={image.type}
                        label={image.label}
                        name={image.name}
                        value={imageV}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="image"
                        error={errors.image && touched.image}
                        success={imageV && imageV.length > 0 && !errors.image}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={official_id.type}
                        label={official_id.label}
                        name={official_id.name}
                        value={officialIdV}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="file"
                        error={errors.official_id && touched.official_id}
                        success={officialIdV && officialIdV.length > 0 && !errors.official_id}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewMemberForm;
