import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {useSnackbar} from "../../../context/SnackbarContext/SnackbarContext";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useSelector} from "react-redux";

const useSession = () => {

    const sessionState = useSelector((state) => state.sessionReducer);

    const { showSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);

    const [sessionFilters, setSessionFilters] = useState(null);
    const [transactionFilters, setTransactionFilters] = useState(null);

    const [statistics, setStatistics] = useState(null);

    const actions = {
        add: false,
        edit: false,
        delete: false,
    };

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            await loadStatistics();
            setIsLoading(false);
        };

        init();
    }, []);

    const loadStatistics = async () => {

        try {

            const response = await axios.get(`${API_URL}/session/statistics`);

            setStatistics(response.data);

        } catch (error) {

            showSnackbar('Statistics', 'Something went wrong loading the statistics', 'cancel', 'warning');
        }

    };

    const renderSessionStatusChip = (status) => {
        switch (status) {
            case 'waiting':
                return <Chip size="small" color="secondary" label="Waiting"
                             variant="outlined"/>;
            case 'playing':
                return <Chip size="small" color="warning" label="Playing"
                             variant="outlined"/>;
            case 'done':
                return <Chip size="small" color="success" label="Done"
                             variant="outlined"/>;
        }
    };

    const renderTypeChip = (status) => {
        switch (status) {
            case 'seat':
                return <Chip size="small" color="success" label="Seating"
                             variant="outlined"/>;
            case 'chips':
                return <Chip size="small" color="warning" label="Chips In"
                             variant="outlined"/>;
            case 'chips out':
                return <Chip size="small" color="error" label="Chips Out"
                             variant="outlined"/>;
        }
    };

    const renderCompare = (current, previous) => {
        return {
            color: current > previous ? 'success' : 'error',
            value: `${current > previous ? '+' : '' }${(((current - previous) / previous) * 100).toFixed(2)}%`,
            label: "since last session",
        }
    };

    return {
        isLoading,
        actions,
        sessionFilters,
        transactionFilters,
        statistics,
        renderSessionStatusChip,
        renderTypeChip,
        renderCompare,
        sessionState
    };
};

export default useSession;
