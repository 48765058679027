import React, {useEffect, useRef, useState} from "react";
import moment from "moment";

const usePlayerSeat = ({player}) => {

    const [timer, setTimer] = useState(null);

    const timerInterval = useRef();
    const timerValue = useRef();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        if(!player){
            setTimer(null);
            return;
        }

        let secondsLeft = 0;

        if(moment(player.finished_at) > moment()){
            secondsLeft = moment(player.finished_at).diff(moment(), 'seconds');
        }

        timerValue.current = secondsLeft;
        setTimer(secondsLeft);
        startTimer();

    }, [player, player?.finished_at]);

    const startTimer = () => {

        clearInterval(timerInterval.current);

        timerInterval.current = setInterval(() => {

            if (timerValue.current > 0) {
                timerValue.current -= 1; // Update the timer value in the ref
                setTimer(timerValue.current);
            } else {
                clearInterval(timerInterval.current);
            }
        }, 1000);
    };

    const renderTimer = () => {

        if(player.finished_at === null){
            return 'Waiting';
        }

        if(player.paused_at !== null){
            return 'Paused';
        }

        const minutes = Math.floor(timer / 60);
        const remainingSeconds = timer % 60;

        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${paddedMinutes}:${paddedSeconds}`;
    };

    const renderBackgroundColor = () => {

        if(!player)
            return 'rgba(0,0,0,0)';

        if(timer < 300)
            return '#ec1c24';

        return 'rgba(255, 255, 255, 0.1)';
    };

    return {
        anchorEl,
        open,
        renderBackgroundColor,
        renderTimer,
        handleMenuClick,
        handleMenuClose
    };
};

export default usePlayerSeat;
