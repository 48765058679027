/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import useWaitingList from "./useWaitingList";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import Message from "../../components/Message/Message";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BaseLayout from "../../components/BaseLayout";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// Settings page components

const WaitingList = () => {

    const {isLoading, sessionState, renderMembers} = useWaitingList();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
                <MDBox p={2}>
                    <Card id="member-list" sx={{overflow: "visible"}}>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                                       alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{fontSize: 24}}>Member</TableCell>
                                                <TableCell style={{fontSize: 24}} align="right">Arrived At</TableCell>
                                                <TableCell style={{fontSize: 24}} align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                renderMembers()
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        }

                    </Card>
                </MDBox>
            </Grid>
        </Grid>
    );
};

export default WaitingList;
