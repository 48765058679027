/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Grid from "@mui/material/Grid";
import DefaultStatisticsCard from "../../../components/Cards/StatisticsCards/DefaultStatisticsCard";
import Card from "@mui/material/Card";
import {API_URL} from "../../../config";
import useSessionDetails from "./useSessionDetails";
import moment from "moment";
import DataTable from "../../../components/DataTable/DataTable";
import MDTypography from "../../../components/MDTypography";
// Session dashboard components
// Data

const SessionDetails = () => {

  const {id, sessionFilters, transactionFilters, actions, isLoading, statistics, renderSessionStatusChip, renderTypeChip, renderCompare} = useSessionDetails();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={2} >
          <MDTypography variant="h4">Session Dashboard</MDTypography>
          <MDTypography variant="subtitle1">Current Session</MDTypography>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="seating sales"
                count={`$${statistics?.transactionsSum.current}`}
                isLoading={isLoading}
                percentage={renderCompare(statistics?.transactionsSum.current, statistics?.transactionsSum.previous)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="members"
                count={`${statistics?.memberCount.current}`}
                isLoading={isLoading}
                percentage={renderCompare(statistics?.memberCount.current, statistics?.memberCount.previous)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Tips"
                count={`$${statistics?.tipsSum.current}`}
                isLoading={isLoading}
                percentage={renderCompare(statistics?.tipsSum.current, statistics?.tipsSum.previous)}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Card id="transactions-list" sx={{overflow: "visible"}}>
            <DataTable
                endpoint={`${API_URL}/session/${id}/transactions`}
                title="Session Transactions"
                description="This are the transactions made during the current session."
                sortBy="created_at"
                sortDirection="desc"
                filters={transactionFilters}
                actions={actions}
                rowsPerPage={5}
                colData={[
                  {
                    name: 'created_at',
                    label: 'Created',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => <span style={{fontWeight: 500}}>{moment(value).format('YYYY-MM-DD')}</span>,
                    },
                  },
                  {
                    name: 'member.name',
                    label: 'Member',
                  },
                  {
                    name: 'amount',
                    label: 'Amount',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => (
                          <span>${value}</span>)
                    }
                  },
                  {
                    name: 'type',
                    label: 'Type',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => renderTypeChip(value),
                      filterType: 'dropdown',
                      filterOptions: {
                        renderValue: (v) => {
                          switch(v){
                            case 'seat': return 'Seating';
                            case 'chips': return 'Chips In';
                            case 'chips out': return 'Chips Out';
                          }
                        }
                      },
                    }
                  },
                ]}
            />
          </Card>
        </MDBox>
        <MDBox mb={3}>
          <Card id="member-list" sx={{overflow: "visible"}}>
            <DataTable
                endpoint={`${API_URL}/session/${id}/members`}
                title="Session Members"
                description="This are the members that checked in at the club during the current session."
                sortBy="arrived_at"
                sortDirection="desc"
                filters={sessionFilters}
                actions={actions}
                rowsPerPage={5}
                colData={[
                  {
                    name: 'member.name',
                    label: 'Name',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => (
                          <span style={{fontWeight: 500}}>{value}</span>)
                    }
                  },
                  {
                    name: 'arrived_at',
                    label: 'Arrived',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                  },
                  {
                    name: 'started_at',
                    label: 'Started Playing',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                  },
                  {
                    name: 'finished_at',
                    label: 'Finished Playing',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                  },
                  {
                    name: 'status',
                    label: 'Status',
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return renderSessionStatusChip(value)
                      }
                    }
                  },
                ]}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SessionDetails;
