/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import React from "react";
import MDBox from "../../../components/MDBox";
import useLobby from "./useLobby";
import BaseLayout from "../../../components/BaseLayout";
import Message from "../../../components/Message/Message";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AssignPlayerForm from "./components/AssignPlayerForm/AssignPlayerForm";
import {Form, Formik} from "formik";
import assignInitialValues from "./schemas/assign/initialValues";
import doneInitialValues from "./schemas/done/initialValues";
import editInitialValues from "./schemas/edit/initialValues";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Icon from "@mui/material/Icon";
import DonePlayerForm from "./components/DonePlayerForm/DonePlayerForm";
import EditPlayerForm from "./components/EditPlayerForm/EditPlayerForm";

// Settings page components

const Lobby = () => {

    const {selectedTable, isLoading, assignCurrentValidation, assignFormId, assignFormField, doneCurrentValidation, doneFormField, doneFormId, editCurrentValidation, editFormField, editFormId, sessionMembers, tables, sessionState, assignModalOpen, doneModalOpen, editModalOpen, renderMembersList, renderTables, handleAssignClose, handleAssign, handleDoneClose, handleDone, handleEditClose, handleEdit, getSeatCost} = useLobby();

    return (
        <BaseLayout>
            {
                !sessionState.isRunning &&
                <MDBox mb={3}>
                    <Message title="No Active Session"
                             message="Heads up, there isn't an active session, some functionality will be disabled. Contact the manager for support."
                             icon="warning"/>
                </MDBox>
            }
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={2}>
                        <MDBox>
                            <Card id="list" sx={{maxHeight: '85vh', overflowY: 'scroll'}}>
                                <MDBox pt={2} px={2} lineHeight={1}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Upcoming Players
                                    </MDTypography>
                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                        Get ready to play!
                                    </MDTypography>
                                </MDBox>
                                <Divider
                                    light={false}
                                />
                                {
                                    isLoading ?
                                        <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox> :
                                        renderMembersList()
                                }
                            </Card>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <MDBox>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Grid container spacing={2}
                                          sx={{display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll'}}>
                                        {renderTables()}
                                    </Grid>
                            }
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Dialog open={assignModalOpen} onClose={handleAssignClose}>
                <Formik
                    initialValues={assignInitialValues}
                    validationSchema={assignCurrentValidation}
                    onSubmit={handleAssign}
                >
                    {({values, errors, touched, isSubmitting}) => (
                        <Form id={assignFormId} autoComplete="off">
                            <DialogTitle>Assign Player</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    New challenger? Enter the amount of half hours the member will play.
                                </DialogContentText>
                                <MDBox mt={2}>
                                    <AssignPlayerForm formData={{values, touched, formField: assignFormField, errors}}/>
                                </MDBox>
                                <MDBox>
                                    <MDTypography variant="h6" sx={{marginBottom: 1}}>Summary</MDTypography>
                                    <TableContainer component={Box}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Item
                                                    </TableCell>
                                                    <TableCell align="right">Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        Half Hours
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            values.is_free == true ?
                                                                `FREE SEATING` :
                                                                values.is_late_night ?
                                                                    `$${values.hours * selectedTable.late_seat_cost} USD` :
                                                                    `$${values.hours * selectedTable.seat_cost} USD`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold'}}>
                                                        Total
                                                    </TableCell>
                                                    <TableCell align="right" sx={{fontWeight: 'bold'}}>${(values.is_free == true ? 0 : values.hours * (values.is_late_night ? selectedTable.late_seat_cost : selectedTable.seat_cost))} USD</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </MDBox>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAssignClose}>Cancel</Button>
                                <Button type="submit">Pay & Assign</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            {/*<Dialog open={doneModalOpen} onClose={handleDoneClose}>*/}
            {/*    <Formik*/}
            {/*        initialValues={doneInitialValues}*/}
            {/*        validationSchema={doneCurrentValidation}*/}
            {/*        onSubmit={handleDone}*/}
            {/*    >*/}
            {/*        {({values, errors, touched, isSubmitting}) => (*/}
            {/*            <Form id={assignFormId} autoComplete="off">*/}
            {/*                <DialogTitle>Mark Player As Done</DialogTitle>*/}
            {/*                <DialogContent>*/}
            {/*                    <DialogContentText>*/}
            {/*                        Member is done playing? Let's register his winnings.*/}
            {/*                    </DialogContentText>*/}
            {/*                    <MDBox mt={2}>*/}
            {/*                        <DonePlayerForm formData={{values, touched, formField: doneFormField, errors}}/>*/}
            {/*                    </MDBox>*/}
            {/*                </DialogContent>*/}
            {/*                <DialogActions>*/}
            {/*                    <Button onClick={handleDoneClose}>Cancel</Button>*/}
            {/*                    <Button type="submit">Mark as Done</Button>*/}
            {/*                </DialogActions>*/}
            {/*            </Form>*/}
            {/*        )}*/}
            {/*    </Formik>*/}
            {/*</Dialog>*/}
            <Dialog open={editModalOpen} onClose={handleEditClose}>
                <Formik
                    initialValues={editInitialValues}
                    validationSchema={editCurrentValidation}
                    onSubmit={handleEdit}
                >
                    {({values, errors, touched, isSubmitting}) => (
                        <Form id={editFormId} autoComplete="off">
                            <DialogTitle>Edit Player</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    More time? Enter the amount of hours the member will play.
                                </DialogContentText>
                                <MDBox mt={2}>
                                    <EditPlayerForm formData={{values, touched, formField: editFormField, errors}}/>
                                </MDBox>
                                <MDBox>
                                    <MDTypography variant="h6" sx={{marginBottom: 1}}>Summary</MDTypography>
                                    <TableContainer component={Box}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Item
                                                    </TableCell>
                                                    <TableCell align="right">Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        Half Hours
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            values.is_free == true ?
                                                                `FREE SEATING` :
                                                                values.is_late_night ?
                                                                    `$${values.hours * selectedTable.late_seat_cost} USD` :
                                                                    `$${values.hours * selectedTable.seat_cost} USD`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold'}}>
                                                        Total
                                                    </TableCell>
                                                    <TableCell align="right" sx={{fontWeight: 'bold'}}>${(values.is_free == true ? 0 : values.hours * (values.is_late_night ? selectedTable.late_seat_cost : selectedTable.seat_cost))} USD</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </MDBox>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleEditClose}>Cancel</Button>
                                <Button type="submit">Pay & Update</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </BaseLayout>
    );
};

export default Lobby;
