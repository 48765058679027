import React from "react";
import Card from "@mui/material/Card";
import Footer from "../../../components/Footer";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import useTables from "./useTables";
import DataTable from "../../../components/DataTable/DataTable";
import {API_URL} from "../../../config";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import MDInput from "../../../components/MDInput";
import moment from "moment";

const Tables = () => {

    const {actions, filters, setFilters, renderStatusChip} = useTables();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <DataTable
                            endpoint={`${API_URL}/tables`}
                            title="Tables"
                            description="Manage the available tables."
                            sortBy="name"
                            sortDirection="asc"
                            filters={filters}
                            actions={actions}
                            colData={[
                                {
                                    name: 'id',
                                    label: 'ID',
                                    options: {
                                        display: 'excluded',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'name',
                                    label: 'Name',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <span style={{fontWeight: 500}}>{value}</span>)
                                    }
                                },
                                {
                                    name: 'seat_cost',
                                    label: 'Seat Price',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <span>${value}</span>)
                                    }
                                },
                                {
                                    name: 'seats_amount',
                                    label: '# Seats'
                                },
                                {
                                    name: 'created_at',
                                    label: 'Created',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                                        filter: true,
                                        filterType: 'custom',
                                        customFilterListOptions: {
                                            render: v => {
                                                if (v[0] && v[1]) {
                                                    return `From: ${v[0]}, To: ${v[1]}`;
                                                } else if (v[0]) {
                                                    return `From: ${v[0]}`;
                                                } else if (v[1]) {
                                                    return `To: ${v[1]}`;
                                                }
                                                return false;
                                            },
                                            update: (filterList, filterPos, index) => {

                                                if (filterPos === 0) {
                                                    filterList[index].splice(filterPos, 1, '');
                                                } else if (filterPos === 1) {
                                                    filterList[index].splice(filterPos, 1);
                                                } else if (filterPos === -1) {
                                                    filterList[index] = [];
                                                }

                                                let newFilters = () => (filterList);
                                                setFilters(newFilters);

                                                // return filterList;
                                            },
                                        },
                                        filterOptions: {
                                            names: [],
                                            logic(date, filters) {
                                                if (filters[0] && filters[1]) {
                                                    return date < filters[0] || date > filters[1];
                                                } else if (filters[0]) {
                                                    return date < filters[0];
                                                } else if (filters[1]) {
                                                    return date > filters[1];
                                                }
                                                return false;
                                            },
                                            display: (filterList, onChange, index, column) => (
                                                <div>
                                                    <MDTypography variant="button"
                                                                  fontWeight="regular">Created</MDTypography>
                                                    <FormGroup row style={{marginTop: 10}}>
                                                        <MDInput
                                                            label="From"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][0] || ''}
                                                            onChange={event => {
                                                                filterList[index][0] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '45%', marginRight: '5%'}}
                                                        />
                                                        <MDInput
                                                            label="To"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][1] || ''}
                                                            onChange={event => {
                                                                filterList[index][1] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '50%'}}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ),
                                        },
                                    },
                                },
                                {
                                    name: 'is_active',
                                    label: 'Status',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return renderStatusChip(value)
                                        }
                                    }
                                }
                            ]}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Tables;
