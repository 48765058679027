import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useMembers = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/members/new-member"),
        edit: (id) => navigate(`/members/${id}/edit-member`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="secondary" label="Active"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactive"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip
    }
};

export default useMembers;
