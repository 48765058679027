/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {dealers},
} = checkout;

const validations = Yup.object().shape({
    [dealers.name]: Yup.array().of(
        Yup.object()
            .shape({
                dealer_id: Yup.number().required(dealers.formField.dealer_id.errorMsg),
                name: Yup.string().required(dealers.formField.dealer_id.errorMsg),
                tips_amount: Yup.number().required(dealers.formField.tips_amount.errorMsg),
            })
            .required(dealers.errorMsg)
    )
});

export default validations;
