/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
// RTL plugins
// Material Dashboard 2 PRO React routes
// Material Dashboard 2 PRO React contexts
import {setMiniSidenav, useMaterialUIController} from "context";
// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import brandMini from "assets/images/logo_mini.png";
import {useDispatch, useSelector} from "react-redux";
import {signIn} from "./store/actions/auth";
import axios from "axios";
import {API_URL} from "./config";
import SignIn from "./layouts/authentication/sign-in";
import Loading from "./layouts/loading";
import {adminRoutes, hostRoutes, staffRoutes} from "./routes";
import RecoverPassword from "./layouts/authentication/recover-password";
import ResetPassword from "./layouts/authentication/reset-password";
import NewUser from "./layouts/users/new-user/NewUser";
import EditUser from "./layouts/users/edit-user/EditUser";
import NewDealer from "./layouts/dealers/new-dealer/NewDealer";
import EditDealer from "./layouts/dealers/edit-dealer/EditDealer";
import NewTable from "./layouts/tables/new-table/NewTable";
import EditTable from "./layouts/tables/edit-table/EditTable";
import {SnackbarProvider} from "./context/SnackbarContext/SnackbarContext";
import SessionDetails from "./layouts/sessions/details/SessionDetails";
import WaitingList from "./layouts/waiting_list/WaitingList";
import NewMember from "./layouts/members/new-member/NewMember";
import EditMember from "./layouts/members/edit-member/EditMember";
import NewPayment from "./layouts/payments/new-payments/NewPayment";
import TablePlayerView from "./layouts/tables/players/TablePlayerView";

export default function App() {
    const [controller, materialDispatch] = useMaterialUIController();

    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();

    const dispatch = useDispatch();

    const authState = useSelector((state) => state.authReducer);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const checkUser = async () => {
            setIsLoading(true);

            try {
                const response = await axios.get(`${API_URL}/user`);

                dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id));

            } catch (error) {

            }

            setIsLoading(false);
        };

        checkUser();

    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(materialDispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(materialDispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    const getRoleRoutes = (roleId) => {
        switch (roleId) {
            case 1:
                return adminRoutes;
            case 2:
                return staffRoutes;
            case 3:
                return hostRoutes;
        }
    };

    return <ThemeProvider theme={darkMode ? themeDark : theme}>
        <SnackbarProvider>
            <CssBaseline/>
            {
                isLoading ?
                    <Loading/> :
                    <>
                        {
                            authState.isLoggedIn ?
                                <>
                                    {layout === "dashboard" && window.location.pathname !== '/dashboards/waiting-list' && !window.location.pathname.includes('/players') && (
                                        <>
                                            <Sidenav
                                                color={sidenavColor}
                                                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                                brandMini={brandMini}
                                                brandName=""
                                                routes={getRoleRoutes(authState.roleId)}
                                                onMouseEnter={handleOnMouseEnter}
                                                onMouseLeave={handleOnMouseLeave}
                                            />
                                        </>
                                    )}
                                    {
                                        authState.roleId === 1 &&
                                        <Routes>
                                            {getRoutes(adminRoutes)}
                                            <Route exact path="/users/new-user" element={<NewUser/>} key="new-user"/>
                                            <Route exact path="/users/:id/edit-user" element={<EditUser/>}
                                                   key="edit-user"/>
                                            <Route exact path="/members/new-member" element={<NewMember/>} key="new-member"/>
                                            <Route exact path="/members/:id/edit-member" element={<EditMember/>}
                                                   key="edit-member"/>
                                            <Route exact path="/dealers/new-dealer" element={<NewDealer/>}
                                                   key="new-dealer"/>
                                            <Route exact path="/dealers/:id/edit-dealer" element={<EditDealer/>}
                                                   key="edit-dealer"/>
                                            <Route exact path="/tables/new-table" element={<NewTable/>}
                                                   key="new-table"/>
                                            <Route exact path="/tables/:id/edit-table" element={<EditTable/>}
                                                   key="edit-table"/>
                                            <Route exact path="/payments/new-payment" element={<NewPayment/>}
                                                   key="new-payment"/>
                                            <Route exact path="/sessions/:id" element={<SessionDetails/>}
                                                   key="session-details"/>
                                           <Route exact path="/tables/:id/players" element={<TablePlayerView/>}
                                                   key="player-view"/>
                                            <Route path="*" element={<Navigate to="/members"/>}/>
                                        </Routes>
                                    }
                                    {
                                        authState.roleId === 2 &&
                                        <Routes>
                                            {getRoutes(staffRoutes)}
                                            <Route exact path="/members/new-member" element={<NewMember/>} key="new-member"/>
                                            <Route exact path="/members/:id/edit-member" element={<EditMember/>}
                                                   key="edit-member"/>
                                            <Route exact path="/payments/new-payment" element={<NewPayment/>}
                                                   key="new-payment"/>
                                            <Route exact path="/sessions/:id" element={<SessionDetails/>}
                                                   key="session-details"/>
                                            <Route exact path="/tables/:id/players" element={<TablePlayerView/>}
                                                   key="player-view"/>
                                            <Route path="*" element={<Navigate to="/members"/>}/>
                                        </Routes>
                                    }
                                </> :
                                <Routes>
                                    <Route exact path="/authentication/sign-in" element={<SignIn/>}
                                           key="sign-in"/>
                                    <Route exact path="/authentication/recover-password" element={<RecoverPassword/>}
                                           key="recover-password"/>
                                    <Route exact path="/authentication/reset-password" element={<ResetPassword/>}
                                           key="reset-password"/>
                                    <Route path="*" element={<Navigate to="/authentication/sign-in"/>}/>
                                </Routes>
                        }
                    </>
            }
        </SnackbarProvider>
    </ThemeProvider>
}
