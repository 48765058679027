import MDButton from "../MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "../MDBox";
import React, {useEffect, useRef, useState} from "react";
import MDTypography from "../MDTypography";
import {formatTime} from "../../helpers/helpers";
import axios from "axios";
import {API_URL} from "../../config";
import {useSnackbar} from "../../context/SnackbarContext/SnackbarContext";
import moment from "moment";
import {useDispatch} from "react-redux";
import {endSession as endSateSession, startSession as startSateSession} from "../../store/actions/session";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import DealerTipsForm from "./components/DealerTipsForm/DealerTipsForm";

const SessionControl = ({mini}) => {

    const dispatch = useDispatch();

    const {formId, formField} = form;
    const currentValidation = validations;
    const [values, setValues] = useState(initialValues);

    const [modalOpen, setModalOpen] = React.useState(false);
    const handleClose = () => setModalOpen(false);

    const [isRunning, setIsRunning] = useState(false);
    const [timer, setTimer] = useState(0);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {showSnackbar} = useSnackbar();

    let timerInterval = useRef();

    useEffect(() => {

        const init = async () => {
            await getCurrentSession();
            await loadDealers();
        };

        init();
    }, []);

    const getCurrentSession = async () => {

        setIsLoading(true);

        try {

            // Enviar datos a API
            const response = await axios.get(
                `${API_URL}/sessions/current`
            );

            if (response.data) {

                const now = moment();
                const sessionStartTime = moment(response.data.start_at, 'YYYY-MM-DD HH:mm:ss');

                const secondsElapsed = now.diff(sessionStartTime, 'seconds');

                dispatch(startSateSession(sessionStartTime));

                setIsRunning(true);
                setTimer(secondsElapsed);
                startTimer();

            }

        } catch (error) {

            showSnackbar('Session', error.response.data.message, 'cancel', 'warning');

        }

        setIsLoading(false);
    };

    const loadDealers = async () => {
        setIsLoading(true);

        try {

            // Enviar datos a API
            const response = await axios.get(
                `${API_URL}/dealers`,
                {
                    params: {
                        is_active: true
                    }
                }
            );

            setValues({
                dealers: response.data.map(dealer => {
                    return {dealer_id: dealer.id, name: dealer.name, tips_amount: 0}
                })
            });


        } catch (error) {

            showSnackbar('Session', error.response.data.message, 'cancel', 'warning');

        }

        setIsLoading(false);
    };


    const handleSessionChange = async () => {

        const newStatus = !isRunning;

        // TODO: handle running
        setIsRunning(newStatus);

        setIsSubmitting(true);

        if (newStatus) {
            await startSession();
            startTimer();
        } else {
            setModalOpen(true);
        }

        setIsSubmitting(false);
    };

    const startSession = async () => {

        try {

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/sessions`
            );

            const sessionStartTime = moment(response.data.start_at, 'YYYY-MM-DD HH:mm:ss');

            dispatch(startSateSession(sessionStartTime));

            showSnackbar('Session', 'Session started, have a great day!', 'notifications', 'secondary');

        } catch (error) {

            showSnackbar('Session', error.response.data.message, 'cancel', 'warning');

        }

    };

    const stopSession = async () => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/sessions`
            );

            dispatch(endSateSession());

            showSnackbar('Session', 'Session ended, good job!', 'notifications', 'secondary');

        } catch (error) {

            showSnackbar('Session', error.response.data.message, 'cancel', 'warning');

        }
    };

    const startTimer = () => {
        timerInterval.current = setInterval(() => {
            setTimer(prevTimer => prevTimer + 1);
        }, 1000)
    };

    const clearTimer = () => {
        clearInterval(timerInterval.current);
        timerInterval.current = null;
        setTimer(0);
    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/dealer_tips`,
                {...values}
            );

            setModalOpen(false);
            actions.resetForm();

            await stopSession();
            clearTimer();

        } catch (error) {

            showSnackbar('Tips', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);

    };

    return <>
        <MDButton color={isRunning ? 'white' : 'secondary'} variant="contained" iconOnly={true} circular={true}
                  disabled={isLoading || isSubmitting}
                  onClick={handleSessionChange}>
            {
                isLoading || isSubmitting ?
                    <CircularProgress size={18} color="primary"/> :
                    <Icon>{isRunning ? 'stop' : 'play_arrow'}</Icon>
            }
        </MDButton>
        {
            !mini &&
            <MDBox ml={1.5}>
                <MDTypography variant="h6" mb={-1}>{isRunning ? 'Stop Session' : 'Start Session'}</MDTypography>
                <MDTypography variant="button" mt={0}>{formatTime(timer)}</MDTypography>
            </MDBox>
        }
        <Dialog open={modalOpen} onClose={handleClose}>
            <Formik
                initialValues={values}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, isSubmitting}) => (
                    <Form id={formId} autoComplete="off">
                        <DialogTitle>Stop Session</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Done for the day? Make sure to register each dealer's tips below.
                            </DialogContentText>
                            <MDBox mt={2}>
                                <DealerTipsForm formData={{values, touched, formField, errors}}/>
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Stop Session & Save</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    </>
};

export default SessionControl;
