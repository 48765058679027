import form from "./schemas/form";
import validations from "./schemas/validations";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {useSnackbar} from "../../../context/SnackbarContext/SnackbarContext";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useSelector} from "react-redux";

const useCheckIn = () => {

    const sessionState = useSelector((state) => state.sessionReducer);

    const {formId, formField} = form;
    const currentValidation = validations;

    const { showSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);

    const [members, setMembers] = useState([]);

    const [filters, setFilters] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const actions = {
        add: false,
        edit: false,
        delete: false,
    };

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadMembers();
            setIsLoading(false);
        };

        init();

    }, []);

    const loadMembers = async () => {

        try {

            const response = await axios.get(`${API_URL}/members`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setMembers([{id: -1, label: "Choose a member"}, ...response.data.map(member => ({id: member.id, label: `${capitalizeFirstLetter(member.name)}`}))]);

        } catch (error) {

            showSnackbar('Check In', 'Something went wrong loading the members', 'cancel', 'warning');

        }

    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/session/members/check-in`,
                {...values}
            );

            showSnackbar('Check In', 'Member successfully checked in', 'notifications', 'success');

            setShouldRefresh(!shouldRefresh);

            actions.resetForm();

        } catch (error) {

            showSnackbar('Check In', error.response.data.message, 'cancel', 'warning');

        }

        actions.setSubmitting(false);

    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 'waiting':
                return <Chip size="small" color="secondary" label="Waiting"
                             variant="outlined"/>;
            case 'playing':
                return <Chip size="small" color="warning" label="Playing"
                             variant="outlined"/>;
            case 'done':
                return <Chip size="small" color="success" label="Done"
                             variant="outlined"/>;
        }
    };

    return {
        currentValidation,
        formId,
        formField,
        isLoading,
        members,
        handleSubmit,
        actions,
        filters,
        setFilters,
        renderStatusChip,
        shouldRefresh,
        setShouldRefresh,
        sessionState
    };
};

export default useCheckIn;
