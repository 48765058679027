

// State inicial del reducer
import {END_SESSION, START_SESSION} from "../actionTypes";

const sessionState = {
    isRunning: false,
    startAt: null,
};

// State de autenticación
const sessionReducer = (state = sessionState, action) => {
    switch(action.type){
        case START_SESSION:
            return { isRunning: true, ...action.payload };
        case END_SESSION:
            return { isRunning: false, startAt: null };
        default:
            return state;
    }
};

export default sessionReducer;
