/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewPaymentForm({formData, members}) {

    const {formField, values, errors, touched} = formData;

    const {member_id, amount, end_at} = formField;
    const {
        member_id: memberIdV,
        amount: amountV,
        end_at: endAtV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={member_id.type}
                        label={member_id.label}
                        name={member_id.name}
                        value={memberIdV}
                        options={members}
                        error={errors.member_id && touched.member_id}
                        success={memberIdV && memberIdV.length > 0 && !errors.member_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={amount.type}
                        label={amount.label}
                        name={amount.name}
                        value={amountV}
                        error={errors.amount && touched.amount}
                        success={amountV && amountV.length > 0 && !errors.amount}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={end_at.type}
                        label={end_at.label}
                        name={end_at.name}
                        options={{enableTime: false, disableMobile: true, dateFormat: 'Y-m-d', minDate: new Date()}}
                        value={endAtV}
                        error={errors.end_at && touched.end_at}
                        success={endAtV && endAtV.length > 0 && !errors.end_at}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewPaymentForm;
