import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useTransactions = () => {

    const [filters, setFilters] = useState({type: ['seat']});

    let navigate = useNavigate();

    const actions = {
        add: false,
        edit: false,
        delete: true,
    };

    const renderTypeChip = (status) => {
        switch (status) {
            case 'seat':
                return <Chip size="small" color="success" label="Seating"
                             variant="outlined"/>;
            case 'chips':
                return <Chip size="small" color="warning" label="Chips In"
                             variant="outlined"/>;
            case 'chips out':
                return <Chip size="small" color="error" label="Chips Out"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderTypeChip
    }
};

export default useTransactions;
