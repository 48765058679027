/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import React from "react";
import initialValues from "./schemas/initialValues";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import useCheckIn from "./useCheckIn";
import BaseLayout from "../../../components/BaseLayout";
import CheckInForm from "./components/CheckInForm/CheckInForm";
import {API_URL} from "../../../config";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import MDInput from "../../../components/MDInput";
import DataTable from "../../../components/DataTable/DataTable";
import moment from "moment";
import Icon from "@mui/material/Icon";
import colors from "../../../assets/theme-dark/base/colors";
import Message from "../../../components/Message/Message";

// Settings page components

const CheckIn = () => {

    const {currentValidation, formId, formField, isLoading, members, handleSubmit, actions, filters, setFilters, renderStatusChip, shouldRefresh, sessionState} = useCheckIn();

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={12}>
                    {
                        !sessionState.isRunning &&
                        <MDBox mb={3}>
                            <Message title="No Active Session" message="Heads up, there isn't an active session, some functionality will be disabled. Contact the manager for support." icon="warning" />
                        </MDBox>
                    }
                    <MDBox mb={3}>
                        <Card id="check-in" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs={12} md={3}>
                                                            <MDTypography variant="h5">Member Check In</MDTypography>
                                                            <MDTypography variant="body2">New member arrived?</MDTypography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <CheckInForm members={members} formData={{
                                                                values,
                                                                touched,
                                                                formField,
                                                                errors
                                                            }}/>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                                size="large"
                                                                fullWidth
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Check In
                                                            </MDButton>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                    <MDBox mb={3}>
                        <Card id="member-list" sx={{overflow: "visible"}}>
                            <DataTable
                                endpoint={`${API_URL}/session/members`}
                                title="Current Members"
                                description="This are the members that checked in at the club during the current session."
                                sortBy="arrived_at"
                                sortDirection="desc"
                                filters={filters}
                                actions={actions}
                                forceLoad={shouldRefresh}
                                colData={[
                                    {
                                        name: 'member.name',
                                        label: 'Name',
                                        options: {
                                            customBodyRender: (value, tableMeta, updateValue) => (
                                                <span style={{fontWeight: 500}}>{value}</span>)
                                        }
                                    },
                                    {
                                        name: 'arrived_at',
                                        label: 'Arrived',
                                        options: {
                                            customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                                        },
                                    },
                                    {
                                        name: 'status',
                                        label: 'Status',
                                        options: {
                                            customBodyRender: (value, tableMeta, updateValue) => {
                                                return renderStatusChip(value)
                                            }
                                        }
                                    },
                                ]}
                            />
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
        </BaseLayout>
    );
};

export default CheckIn;
