/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import React from "react";
import initialValues from "./schemas/initialValues";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";
import NewDealerForm from "./components/NewDealerForm/NewDealerForm";
import useNewDealer from "./useNewDealer";
import BaseLayout from "../../../components/BaseLayout";

// Settings page components

const NewDealer = () => {

    const {show, currentValidation, formId, formField, isLoading, snackbarConfig, handleSubmit, toggleSnackbar} = useNewDealer();

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <MDBox mb={3}>
                        <Card id="new-dealer" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography variant="h5">New Dealer</MDTypography>
                                                    <MDTypography variant="body2">Enter the new dealer's details.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <NewDealerForm formData={{values, touched, formField, errors}} />
                                                    <MDBox mt={2} width="100%" display="flex"
                                                           justifyContent="flex-end">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Create
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
};

export default NewDealer;
