/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import React from "react";
import MDBox from "../../../components/MDBox";
import useTablePlayerView from "./useTablePlayerView";
import BaseLayout from "../../../components/BaseLayout";
import Message from "../../../components/Message/Message";
import tableImage from "assets/images/table.png";
import MDTypography from "../../../components/MDTypography";

// Settings page components

const TablePlayerView = () => {

    const {isLoading, tableMembers, sessionState, renderPlayers} = useTablePlayerView();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
                    {
                        isLoading ?
                            <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                                   alignItems="center">
                                <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                            </MDBox> :
                            <MDBox sx={{backgroundImage: `url(${tableImage})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}} height="100vh">
                                {
                                    renderPlayers()
                                }
                            </MDBox>
                    }
            </Grid>
        </Grid>
    );
};

export default TablePlayerView;
