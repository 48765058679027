/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-member-form",
  formField: {
    member_id: {
      name: "member_id",
      label: "Member",
      type: "select",
      errorMsg: "Member is required.",
      invalidMsg: "You must select a member from the list.",
    },
    amount: {
      name: "amount",
      label: "Total",
      type: "number",
      errorMsg: "Total is required."
    },
    end_at: {
      name: "end_at",
      label: "Ends At",
      type: "date",
      errorMsg: "Ends at is required.",
    },
  },
};

export default form;
