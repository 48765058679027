import MDBox from "../../../components/MDBox";
import Message from "../../../components/Message/Message";
import Grid from "@mui/material/Grid";
import BaseLayout from "../../../components/BaseLayout";
import React from "react";
import useDealersDashboard from "./useDealersDashboard";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import {Form, Formik} from "formik";
import initialValues from "./schemas/initialValues";
import AssignDealerForm from "./components/AssignDealerForm/AssignDealerForm";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const DealersDashboard = () => {

    const {formField, dealers, formId, currentValidation, isLoading, sessionState, modalOpen, handleClose, handleAssign, renderTables} = useDealersDashboard();

    return <BaseLayout>
        {
            !sessionState.isRunning &&
            <MDBox mb={3}>
                <Message title="No Active Session"
                         message="Heads up, there isn't an active session, some functionality will be disabled. Contact the manager for support."
                         icon="warning"/>
            </MDBox>
        }
        <MDBox mb={3}>
            <Grid container spacing={3}>
                {
                    isLoading ?
                        <MDBox mt={2} mx={2} p={3} display="flex" justifyContent="center"
                               alignItems="center">
                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                        </MDBox> :
                        renderTables()
                }
            </Grid>
        </MDBox>
        <Dialog open={modalOpen} onClose={handleClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleAssign}
            >
                {({values, errors, touched, isSubmitting}) => (
                    <Form id={formId} autoComplete="off">
                        <DialogTitle>Assign Dealer</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Assign a new dealer to the table
                            </DialogContentText>
                            <MDBox mt={2}>
                                <AssignDealerForm dealers={dealers} formData={{values, touched, formField, errors}}/>
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Assign</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    </BaseLayout>
};

export default DealersDashboard;
